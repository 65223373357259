@font-face {
  font-family: 'Akzidenz-Grotesk Pro';
  src: local('Akzidenz-Grotesk Pro Light'), local('AkzidenzGroteskPro-Light'),
  url('./akzidenzGroteskPro/AkzidenzGroteskPro-Light.woff2') format('woff2'),
  url('./akzidenzGroteskPro/AkzidenzGroteskPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro';
  src: local('Akzidenz-Grotesk Pro Regular'), local('AkzidenzGroteskPro-Regular'),
  url('./akzidenzGroteskPro/AkzidenzGroteskPro-Regular.woff2') format('woff2'),
  url('./akzidenzGroteskPro/AkzidenzGroteskPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro';
  src: local('Akzidenz-Grotesk Pro Bold'), local('AkzidenzGroteskPro-Bold'),
  url('./akzidenzGroteskPro/AkzidenzGroteskPro-Bold.woff2') format('woff2'),
  url('./akzidenzGroteskPro/AkzidenzGroteskPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro';
  src: local('Akzidenz-Grotesk Pro ExtraBold'), local('AkzidenzGroteskPro-XBd'),
  url('./akzidenzGroteskPro/AkzidenzGroteskPro-XBd.woff2') format('woff2'),
  url('./akzidenzGroteskPro/AkzidenzGroteskPro-XBd.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}
